import { ProductsCarousel } from 'components/Product/Products/ProductsCarousel';
import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { useEffect, useState } from 'react';
import client from '../../../../client';
// import productData from 'data/product/product';

export const MostViewed = ({ additionalClass }) => {
  const [products, setProducts] = useState([])
  const mostViewed = [...products].slice(0, 6);

  useEffect(() => {
    const query = `*[_type == "Toy"]`
    client
      .fetch(query)
      .then(data => {
        const discountedProducts = data.map(item => {
          if (item.hasOwnProperty('discount')) {
            return { ...item, originalPrice: item.price, price: item.price - (item.discount / 100 * item.price) };
          } else {
            return { ...item, originalPrice: 0 };
          }
        });
        setProducts([...discountedProducts])
      })
  }, [])

  return (
    <>
      {/* <!-- BEGIN MOST VIEWED --> */}
      <section className={`arrivals ${additionalClass ? additionalClass : ''}`}>
        {/* <SectionTitle
          subTitle='Toyz'
          title='You Have Viewed'
        /> */}

        {/* <div className='products-items'>
          <ProductsCarousel products={mostViewed} />
        </div> */}
      </section>
      {/* <!-- MOST VIEWED EOF --> */}
    </>
  );
};
