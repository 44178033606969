import React, { useState, useEffect } from "react";
import paymentMethodData from "data/footer/payment";
import Link from "next/link";
import { NavCol } from "./NavCol/NavCol";
import { BiLogoMedium } from "react-icons/bi";
import { PiGoogleLogoBold } from "react-icons/pi";
import { IoMdCloseCircle } from "react-icons/io";

export const Footer = () => {
  const footerLogo = "/assets/img/jusplay.svg";

  const footerNavData1 = [
    {
      title: "Quick Links",
      navLinks: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Shop",
          path: "/shop",
        },
        {
          name: "Privacy Policy",
          path: "/privacy",
        },
        {
          name: "User friendly terms",
          path: "/terms",
        },
        {
          name: (
            <div
              style={{ display: "flex", flexDirection: "column", fontSize: 14 }}
            >
              <span>Rated 4.9 🌟 on Google</span>
              <span>Share Your REVIEW</span>
            </div>
          ),
          path: "https://g.page/r/CTVd6g8-zLdcEB0/review",
          size: 120,
        },
      ],
    },
    {
      title: "Categories",
      navLinks: [
        {
          name: "Active Play",
          path: "/category/active-play",
        },
        {
          name: "All Time Favourites",
          path: "/category/all-time-favourites",
        },
        {
          name: "Learn With Fun",
          path: "/category/learn-with-fun",
        },
        {
          name: "Pretend Play",
          path: "/category/pretend-play",
        },
        {
          name: "Cars & Rides",
          path: "/category/cars-and-rides",
        },
      ],
    },
    // {
    //   title: 'Support',
    //   navLinks: [
    //     {
    //       name: 'Sign Up',
    //       path: '/registration',
    //     },
    //     {
    //       name: 'My Account',
    //       path: '/profile',
    //     },
    //   ],
    // },
  ];

  const socialData = [
    {
      icon: "icon-facebook",
      path: "https://www.facebook.com/jusplaykids",
    },
    {
      icon: "icon-insta",
      path: "https://www.instagram.com/jusplay_toys/",
    },
  ];

  const footerNav = [...footerNavData1];
  const footerSocial = [...socialData];
  const paymentMethods = [...paymentMethodData];

  const [showHelpText, setShowHelpText] = useState(true);
  const [animateOut, setAnimateOut] = useState(false);

  useEffect(() => {
    // Set a timeout to hide the help text after 5 seconds
    const timer = setTimeout(() => {
      setShowHelpText(false);
    }, 5000);

    return () => {
      // Clear the timer when the component unmounts
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {/* <!-- BEGIN FOOTER --> */}
      <footer className="footer">
        <div className="wrapper">
          <div className="footer-top">
            <div className="footer-top__social">
              <span>Find us here:</span>
              <ul>
                {footerSocial.map((social, index) => (
                  <li key={index}>
                    <a href={social.path}>
                      <i className={social.icon}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer-top__logo">
              <Link href="/">
                <a>
                  <img src={footerLogo} className="js-img" alt="" />
                </a>
              </Link>
            </div>

            {/* Payment method */}
            <div className="footer-top__payments">
              <span>Payment methods:</span>
              <ul>
                {paymentMethods.map((payment, index) => (
                  <li key={index}>
                    <img
                      style={{ width: 120 }}
                      src={payment.icon}
                      className="js-img"
                      alt=""
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="footer-nav">
            {/* Footer Nav */}
            {footerNav.map((nav, index) => (
              <NavCol nav={nav} key={index} />
            ))}
            <div className="footer-nav__col">
              <span className="footer-nav__col-title">Support</span>
              <ul>
                <li>
                  <i className="icon-smartphone"></i>
                  <div className="footer-nav__col-phones">
                    Toy Renting: <a href="tel:+919315303419">+91 9315303419</a>
                  </div>
                </li>
                <li className="footer-nav-mail">
                  <i className="icon-mail"></i>
                  <a href="mailto:info@jusplaykids.com">info@jusplaykids.com</a>
                </li>
              </ul>
            </div>
            <div className="footer-nav__col">
              <span className="footer-nav__col-title">Contact</span>
              <ul>
                <li className="footer-nav__col-address">
                  <i className="icon-map-pin"></i> JusPlay, Q1/37, Ground Floor,
                  South City II, Sector 49, Gurugram.
                </li>
                <li>
                  <i className="icon-smartphone"></i>
                  <div className="footer-nav__col-phones">
                    Party Playarea:
                    <a href="tel:+917037751196">+91 7037751196</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-copy">
            <span>© 2022 Jusplaytoys. All Right Reserved</span>
            <br />
            <span>
              Designed & Developed By -{" "}
              <a target="_blank" href="https://www.aksinteractive.com/">
              AKS Interactive Solutions
              </a>
            </span>
          </div>
        </div>
        {/* {showHelpPopup && (
          <div className="help-popup">
            <p>Happy To Help You.</p>
            <span className="btn-popup" onClick={handleClosePopup}>
              <IoMdCloseCircle size={30} />
            </span>
          </div>
        )} */}

        <div>
          <a
            target="_blank"
            style={{
              display: "flex",
              alignItems: "center",
              padding: "5px",
            }}
            className="whatsapp-icon"
            href="https://api.whatsapp.com/send/?phone=919315303419&text=Happy+to+know+that+you+want+to+rent+toys+from+Jusplaytoys+%F0%9F%A4%A9%0AWe+are+excited+to+answer+your+questions+and+make+sure+you+have+a+great+time+renting+toys+from+us&type=phone_number&app_absent=0"
          >
            <img
              src="/assets/img/whatsapp.png"
              name="whatsapp"
              alt=""
              style={{ width: 50, height: 50 }}
            />
            {showHelpText && (
              <span
                className={`happy-to-help ${animateOut ? "fade-out" : ""}`}
                style={{ color: "#000" }}
              >
                Happy to help you
              </span>
            )}
          </a>
        </div>
      </footer>
      {/* <!-- FOOTER EOF   --> */}
    </>
  );
};
