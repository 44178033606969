import Link from 'next/link';

export const NavCol = ({ nav }) => {
  return (
    <div className='footer-nav__col'>
      <span className='footer-nav__col-title'>{nav.title}</span>
      <ul>
        {nav?.navLinks?.map((nav, indx) => (
          <li
            key={nav.name + indx}
            className={nav?.size && 'footer_nav_google_review_li'}
          >
            <Link href={nav.path}>
              <a className={nav?.size && 'footer_nav_google_review'}>
                {nav.name}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
