import StarRating from "components/ReviewCard";
import Link from "next/link";
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "context/UserContext";
import { FaStar } from "react-icons/fa";
import { useRouter } from "next/router";

export const SingleProduct = ({ product, onAddToWish, small, type }) => {
  console.log("single product comp", product);
  const {
    name,
    oldPrice,
    price,
    image,
    isSale,
    isNew,
    _id,
    slug,
    mainImage,
    discount,
    originalPrice,
    reviews,
  } = product;

  function replaceURL(url) {
    return url?.replace(
      "https://jusplaytoys.fra1.digitaloceanspaces.com",
      "https://main-object-storage.blr1.cdn.digitaloceanspaces.com"
    );
  }
  console.log(product, "heloo");
  const { user } = useContext(UserContext);
  const router = useRouter();

  const [starRating, setStarRating] = useState({
    oneStar: 0,
    twoStar: 0,
    threeStar: 0,
    fourStar: 0,
    fiveStar: 0,
  });

  const ratingCalculator = () => {
    const initialRatingCount = {
      oneStar: 0,
      twoStar: 0,
      threeStar: 0,
      fourStar: 0,
      fiveStar: 0,
    };

    // Accumulate counts for each star rating
    const updatedRating = product?.reviews?.reduce((acc, review) => {
      switch (review?.star) {
        case 1:
          acc.oneStar += 1;
          break;
        case 2:
          acc.twoStar += 1;
          break;
        case 3:
          acc.threeStar += 1;
          break;
        case 4:
          acc.fourStar += 1;
          break;
        case 5:
          acc.fiveStar += 1;
          break;
        default:
          // Handle cases where review.star is not 1-5, if necessary
          break;
      }
      return acc;
    }, initialRatingCount);

    // Update the state with the accumulated counts
    setStarRating(updatedRating);
  };
  useEffect(() => {
    if (product) {
      // Checks if singleProduct is not null or undefined

      ratingCalculator();
    }
  }, [product, user]); // Dependency array ensures effect runs only when singleProduct changes

  // Update the state with the accumulated counts

  console.log(starRating, "123______star____rating");

  function calculateAverageRating(ratings) {
    // Initialize variables to store the sum of weighted ratings and the total count of ratings
    let sumOfWeightedRatings = 0;
    let totalCountOfRatings = 0;

    // Object mapping each rating category to its value
    const ratingValues = {
      oneStar: ratings?.oneStar || 0,
      twoStar: ratings?.twoStar || 0,
      threeStar: ratings?.threeStar || 0,
      fourStar: ratings?.fourStar || 0,
      fiveStar: ratings?.fiveStar || 0,
    };

    // Iterate over the rating categories to calculate the sum of weighted ratings and total count
    Object.keys(ratingValues).forEach((key, index) => {
      const count = ratingValues[key] || 0; // Default to 0 if undefined
      if (count > 0) {
        // Consider only counts that are greater than 0
        sumOfWeightedRatings += (index + 1) * count; // Multiply the star value (index + 1) by the count
        totalCountOfRatings += count; // Increment the total count by the count of the current category
      }
    });

    // Calculate the average rating. If totalCountOfRatings is 0 (i.e., no valid ratings), return "N/A"
    const averageRating =
      totalCountOfRatings > 0
        ? (sumOfWeightedRatings / totalCountOfRatings).toFixed(1)
        : "N/A";

    return averageRating;
  }

  const [checkboxState, setCheckboxState] = useState(false);

  useEffect(() => {
    // Initialize the checkbox as unchecked for the current product
    setCheckboxState(false);
  }, [product]);

  // State to keep track of all selected (checked) product IDs

  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const handleCheckboxChange = (isChecked, productId) => {
    console.log(isChecked,'isChecked')
    if (isChecked) {
      console.log(selectedProductIds, "check1");
      setSelectedProductIds(prevSelected => [...prevSelected, productId]);
    } else {
      console.log(selectedProductIds, "check2");
      setSelectedProductIds(prevSelected => prevSelected.filter(id => id !== productId));
    }
  };

  


  console.log(selectedProductIds.length, "check");

  return (
    <>
      {/* <!-- BEING SINGLE PRODUCT ITEM --> */}
      <div
        className={`${
          type === "homepage" ? "products-item home" : "products-item"
        }`}
        style={{
          position: "relative", // Needed for absolute positioning of the checkbox
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          
        }}
      >
        <div style={{ position: "relative" }}>
          {router.pathname === "/basic" && (
            <>
              <input
                id={`customCheckbox-${product._id}`}
                type="checkbox"
                checked={selectedProductIds.includes(product._id)}
                onChange={(e) => handleCheckboxChange(e.target.checked, product._id)}
                style={{ display: "none" }}
              />
              <label
                htmlFor={`customCheckbox-${product._id}`}
                className={`customCheckboxLabel ${
                  selectedProductIds.includes(product._id) ? "checked" : ""
                }`}
              ></label>
            </>
          )}

          <div className="products-item__type">
            {isSale && <span className="products-item__sale">sale</span>}
            {isNew && <span className="products-item__new">new</span>}
          </div>
          <Link href={`/product/${slug?.current}`}>
            <a>
              <div
                className={
                  small ? "products-item__img" : "products-item__img_bg"
                }
              >
                {/*<img src={urlFor(image).url()} className='js-img' alt='' />*/}
                <img src={replaceURL(mainImage)} className="js-img" alt="" />
                <div className="products-item__hover">
                  {/* <Link href={`/product/${slug?.current}`}>
                 <a> */}
                  <i className="icon-search"></i>
                  {/* </a>
                 </Link> */}
                  <div className="products-item__hover-options">
                    {/*<button className='addList' onClick={() => onAddToWish(_id)}>*/}
                    {/*  <i className='icon-heart'></i>*/}
                    {/*</button>*/}
                    {/*<button*/}
                    {/*  disabled={addedInCart}*/}
                    {/*  className={`addList ${addedInCart ? 'added' : ''}`}*/}
                    {/*  onClick={() => onAddToCart(_id)}*/}
                    {/*>*/}
                    {/*  <i className='icon-cart'></i>*/}
                    {/*</button>*/}
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </div>

        <div className="products-item__info">
          <Link href={`/product/${slug?.current || "product"}`}>
            <a>
              <span className="products-item__name">{name}</span>
            </a>
          </Link>
          <span className="products-item__cost">
            <span>{oldPrice && `₹${oldPrice}`}</span> ₹ {price}/Week{" "}
            <span
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                fontSize: "15px",
                textDecoration: "line-through",
              }}
            >
              {originalPrice ? `₹${originalPrice}` : ""}
            </span>{" "}
            <strong
              style={{ color: "#D05E5E", fontSize: "15px", fontWeight: 500 }}
              // style={{ color: '#70AAEF', fontSize: '15px', fontWeight: 500 }}
            >
              {discount && `(${discount}%)`}
            </strong>
          </span>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              margin: "1rem auto",
              backgroundColor: "#ffa41c",
              borderRadius: "5px",
              padding: "5px",
              width: "175px",
            }}
          >
            {" "}
            {calculateAverageRating(starRating)}{" "}
            <FaStar size={20} color="#fff" />
            <span>/</span>
            <span>
              {product?.reviews !== undefined ? product?.reviews?.length : 0}{" "}
              reviews
            </span>
          </div> */}
        </div>
      </div>
      {/* <!-- SINGLE PRODUCT ITEM EOF --> */}
    </>
  );
};
